/* ITEMS */
div.ant-form-item {
  margin-bottom: 24px;
}

.ant-form-item-control {
  position: relative;

  .ant-form-item-explain {
    position: absolute;
    font-size: $font-size-small;
    line-height: $line-height-small;
    bottom: -25px;
  }
}

/* LABELS */
.ant-form-item-label{
  padding-bottom: 0 !important;
  font-weight: bold;

  & > label.ant-form-item-required {
    flex-direction: row-reverse;

    &::before{
      color: $secondary-color !important;
    }
  }
}

/* INPUTS */
input.ant-input{
  padding: $input-padding;
  font-size: $font-size;
  line-height: $line-height;
  border: 1px solid $grey;
  border-radius: $border-radius;

  &.readonly-form-field {
    cursor: not-allowed;
    background-color: $light-grey;
    border: 1px solid $grey;
    color: $dark-grey;
  }

  &::placeholder {
    color: $dark-grey;
  }
}

/* TEXT AREAS */
textarea.ant-input{
  padding: $input-padding;
  font-size: $font-size;
  line-height: $line-height;
  border: 1px solid  $grey;
  border-radius: $border-radius;

  &.readonly-form-field {
    cursor: not-allowed;
    background-color: $light-grey;
    border: 1px solid $grey;
    color: $dark-grey;
    box-shadow: none !important;
  }

  &::placeholder {
    color: $dark-grey;
  }
}

/* SELECTS */
.ant-select.ant-select-single:not(.ant-select-customize-input){
  .ant-select-selector{
    padding: $input-padding;
    font-size: $font-size;
    line-height: $line-height;
    border: 1px solid  $grey;
    border-radius: $border-radius;
    height: $input-height;

    .ant-select-selection-placeholder {
    color: $dark-grey;
    }

    .ant-select-selection-search-input{
      height: 100%;
    }
    .ant-select-selection-item,
    .ant-select-selection-placeholder{
      line-height: inherit;
    }
  }
  &.ant-select-disabled{
    .ant-select-selector {
      cursor: not-allowed;
      background-color: $light-grey;
      border: 1px solid $grey;
      color: $dark-grey;
    }
  }
}

.ant-select.ant-select-multiple:not(.ant-select-customize-input){
  .ant-select-selector {
    padding: 2.5px 16px;
    font-size: $font-size;
    line-height: $line-height;
    border: 1px solid  $grey;
    border-radius: $border-radius;

    .ant-select-selection-placeholder {
      color: $dark-grey;
    }
    .ant-select-selection-item-remove{
      display: flex !important;
      align-items: center;
    }
  }
  &.ant-select-disabled{
    .ant-select-selector {
      cursor: not-allowed;
      background-color: $light-grey !important;
      border: 1px solid $grey;
      color: $dark-grey;
    }
  }
}

/* IMG UPLOADS */
.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select-picture-card{
  margin-bottom: 0;
  margin-right: 0;
  border-radius: 0;
  background-color: $white;
}
.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select-picture-card .ant-upload .ant-upload-button{
  color: $black;
  opacity: .4;
}

.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select-picture-card.ant-upload-disabled{
  background-color: $light-grey;
}
.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select-picture-card.ant-upload-disabled .ant-upload.ant-upload-disabled .ant-upload-button{
  color: $dark-grey;
  opacity: 1;
}

.ant-form-item-has-error .ant-upload-picture-card-wrapper .ant-upload.ant-upload-select-picture-card{
  border-color: $danger-color;
}

.ant-upload .filename {
  margin-top: 8px;
  display: inline;
}

.ant-upload .delete-icon {
  font-size: 20px;
}

.ant-upload .delete-icon:hover {
  color: $secondary-color;
}

.ant-upload.ant-upload-file {
  display: flex;
  min-height: 100px !important;
  align-items: center;
}

/* RADIO GROUPS */
.ant-radio-group{
  .ant-radio-button-wrapper{
    border-radius: $border-radius;
    padding: $input-padding;
    font-size: $font-size;
    line-height: $line-height;
    height: auto;
    border-width: 1px;
  }
}

/* DATE PICKERS */
.ant-picker:not(.ant-picker-range) {
  padding: $input-padding;
  border-radius: $border-radius;
  width: 100%;
  border: 1px solid $grey;

  .ant-picker-input > input {
    font-size: $font-size;
    line-height: $line-height;

    &::placeholder{
      color: $dark-grey;
    }
  }

  .ant-picker-suffix{
    color: $dark-grey;
  }
}
.ant-picker.ant-picker-range {
  padding: $input-padding;
  border-radius: $border-radius;
  width: 100%;
  border: 1px solid $grey;

  .ant-picker-input > input {
    text-align: center;
    font-size: $font-size;
    line-height: $line-height;

    &::placeholder{
      color: $dark-grey;
    }
  }

  .ant-picker-suffix{
    color: $dark-grey;
  }
}

@media (max-width: $max-md-breakpoint){
  .ant-picker-panels > *:first-child button.ant-picker-header-next-btn {
    visibility: visible !important;
  }
  .ant-picker-panels > *:first-child button.ant-picker-header-super-next-btn {
    visibility: visible !important;
  }
  .ant-picker-panels > *:last-child {
    display: none !important;
  }
  .ant-picker-panel-container, .ant-picker-footer {
    width: 280px !important;
  }
  .ant-picker-footer-extra > div {
    flex-wrap: wrap !important;
  }
}

/* FIRST FOM ITEM STICKY TOP */
.form-first-item-sticky-top-container{
  margin: -44px -24px 40px;
  padding-top: 20px;
  position: sticky;
  top: -32px;
  z-index: 2;
  box-shadow: 0 10px 10px -10px rgba(0,32,91,0.2);
}
.form-first-item-sticky-top-content{
  padding: 20px 24px 20px;
  background-color: $white;
  border-radius: $border-radius $border-radius 0 0;
}

/* ERRORS */
.ant-form-item.ant-form-item-has-error {

  input.ant-input:enabled,
  textarea.ant-input:enabled,
  .ant-picker:not(.ant-picker-disabled) .ant-picker-input {
    border-color: $danger-color;
    color: $danger-color;

    &:focus,
    &:hover {
      border-color: $danger-color;
    }
    &::placeholder {
      color: $danger-color;
    }
  }

  .ant-select.ant-select-single:not(.ant-select-customize-input):not(.ant-select-disabled) .ant-select-selector,
  .ant-select.ant-select-multiple:not(.ant-select-customize-input):not(.ant-select-disabled) .ant-select-selector {
    .ant-select-selection-placeholder {
      color: $danger-color;
    }
  }

  &.merged {
    .ant-input-group {
      border-color: $danger-color;

      .ant-input-group-addon {
        border: 0;
      }

      .ant-input {
        border: 0;
      }
    }
  }

  & + .custom-error {
    color: $danger-color;
    margin-top: -23px;
    font-size: $font-size-small;
    line-height: $line-height-small;
  }
}

.custom-error.constant {
  color: $danger-color;
  margin-top: -23px;
  font-size: $font-size-small;
  line-height: $line-height-small;

  & .custom-valid {
    color: $success-color;
  }
}
