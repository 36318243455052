button.ant-btn{
  height: auto !important;
  padding: $button-padding;
  border-radius: $border-radius;
  border: 1px solid;
  font-size: $font-size;
  line-height: $line-height;
  letter-spacing: $letter-spacing;
  transition: none;
  outline: none !important;
  box-shadow: none !important;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  .icon{
    display: flex;
    align-items: center;
    justify-content: center;
    height: $line-height;
  }

  &.btn-icon{
    height: $button-height !important;
    min-width: $button-height;
    max-width: $button-height;
    padding: 0;
  }
}

.ant-btn-primary, .ant-btn-primary:focus, .ant-btn-primary:active,
.btn-primary, .btn-primary:focus, .btn-primary:active {
  @include btn-variant($primary-color, $primary-color, $white);
}

.btn-primary-outlined, .btn-primary-outlined:focus, .btn-primary-outlined:active {
  @include btn-variant-lighten($white, $primary-color, $primary-color);
}

.btn-secondary, .btn-secondary:focus, .btn-secondary:active {
  @include btn-variant($secondary-color, $secondary-color, $white);
}

.btn-tertiary, .btn-tertiary:focus, .btn-tertiary:active {
  @include btn-variant($tertiary-color, $tertiary-color, $white);
}

.btn-success, .btn-success:focus, .btn-success:active {
  @include btn-variant($success-color, $success-color, $white);
}

.btn-warning, .btn-warning:focus, .btn-warning:active {
  @include btn-variant($warning-color, $warning-color, $white);
}

.btn-danger, .btn-danger:focus, .btn-danger:active {
  @include btn-variant($danger-color, $danger-color, $white);
}

.btn-black, .btn-black:focus, .btn-black:active {
  @include btn-variant($black, $black, $white);
}

.btn-white, .btn-white:focus, .btn-white:active {
  @include btn-variant-darken($white, $white, $black);
}

.btn-grey, .btn-grey:focus, .btn-grey:active {
  @include btn-variant-darken($grey, $grey, $black);
}

.btn-dark-grey, .btn-dark-grey:focus, .btn-dark-grey:active {
  @include btn-variant-darken($dark-grey, $dark-grey, $black);
}

.btn-light-grey, .btn-light-grey:focus, .btn-light-grey:active {
  @include btn-variant-darken($light-grey, $light-grey, $black);
}

.btn-transparent, .btn-transparent:focus, .btn-transparent:active {
  @include btn-variant-lighten(transparent, transparent, $black);
}

.btn-transparent-black, .btn-transparent-black:focus, .btn-transparent-black:active {
  @include btn-variant-darken(transparent, transparent, $black);
  &:hover {
    color: $primary-color !important;
  }
}

[ant-click-animating-without-extra-node].btn-transparent:after {
  -webkit-animation: none !important;
  -moz-animation: none !important;
  -o-animation: none !important;
  -ms-animation: none !important;
  animation: none !important;
}

[ant-click-animating-without-extra-node].btn-transparent-black:after {
  -webkit-animation: none !important;
  -moz-animation: none !important;
  -o-animation: none !important;
  -ms-animation: none !important;
  animation: none !important;
}

.ant-btn[disabled] {
  background-color: $dark-grey !important;
  border-color: $dark-grey !important;
  color: $white !important;
}

.btn-return, .btn-return:focus, .btn-return:active {
  min-width: 0 !important;
  padding: 0 !important;
  position: absolute;
  top: 10px;
  left: 24px;

  svg{
    fill: $secondary-color !important;

    path{
      fill: $secondary-color !important;
    }
  }

  &:hover{
    svg{
      fill: $secondary-color !important;

      path{
        fill: $secondary-color !important;
      }
    }
  }
}
