html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-family: $font-family;
  background-color: $body-bg !important;
  color: $font-color !important;
  font-size: $font-size !important;
  line-height: $line-height !important;
  letter-spacing: $letter-spacing !important;
  overflow-y: hidden;
}

.global {
  min-height: 100%;
  height: 100%; // IE11
  display: flex;
  flex-direction: column;

  main {
    flex-grow: 1;
    display: flex;

    .scrollable-container{
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      max-height: 100vh;

      &.with-header{
        max-height: calc(100vh - #{$header-height});

        @media (max-width: $max-header-breakpoint) {
          max-height: calc(100vh - #{$header-height-mobile});
        }
      }

      .app-content{
        flex-grow: 1;
        padding: $body-padding;

        @media (max-width: $max-sm-breakpoint){
          padding: $body-padding-sm;
        }

        &.content-contained{
          width: 100%;
          margin-left: auto;
          margin-right: auto;
        }
        &.content-sm{
          max-width: $container-max-width-sm;
        }
        &.content-md{
          max-width: $container-max-width-md;
        }
        &.content-lg{
          max-width: $container-max-width-lg;
        }
      }
    }
  }
}
