header{
  background-color: $header-bg;
  height: $header-height;
  box-shadow: $box-shadow-header;


  .logo-container{
    padding: 0 16px;

    .logo{
      height: $header-logo-size;
    }
  }


  .user-container{
    margin-right: 16px;
  }

  @media (max-width: $max-header-breakpoint){
    height: $header-height-mobile;
    padding: 0 $header-height-mobile 0 0;

    .logo-container{
      padding: 0 4px;
      .logo{
        height: $header-logo-size-mobile;
      }
    }

    .user-container{
      display: none !important;
    }
  }

  &.sidemenu-closable-on-desktop{
    padding-right: $header-height;
  }
}
