.app-tag{
  font-size: $font-size-small;
  line-height: $line-height-small;
  font-weight: bold;
  padding: 6.5px 12px;
  border-radius: 24px;
  @include tag-variant($black);

  &.tag-primary{
    @include tag-variant($primary-color);
  }
  &.tag-secondary{
    @include tag-variant($secondary-color);
  }
  &.tag-tertiary{
    @include tag-variant($tertiary-color);
  }
  &.tag-success{
    @include tag-variant($success-color);
  }
  &.tag-warning{
    @include tag-variant($warning-color, $black);
  }
  &.tag-danger{
    @include tag-variant($danger-color);
  }
  &.tag-white{
    @include tag-variant($white, $black);
  }
  &.tag-grey{
    @include tag-variant($grey, $black);
  }
  &.tag-dark-grey{
    @include tag-variant($dark-grey, $black);
  }
  &.tag-light-grey{
    @include tag-variant($light-grey, $black);
  }
}
