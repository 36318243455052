.app-modal.ant-modal{
  .ant-modal-content{
    border-radius: $border-radius;
    .ant-modal-header{
      border: none;
      padding: 24px 32px 8px;
      border-radius: $border-radius $border-radius 0 0;
      .ant-modal-title{
        font-weight: bold;
        font-size: 20px;
        line-height: 28px;
        color: $font-color;
      }
    }
    .ant-modal-body{
      padding: 0 32px 24px;
    }
    .ant-modal-footer{
      border: none;
      padding: 0 32px 24px;
      border-radius: 0 0 $border-radius $border-radius;

      .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
        margin-left: 10px;
      }
    }
  }
}
