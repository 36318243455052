/* COULEURS */
// Certaines couleurs de base de antd sont dans config-overrides.js
$primary-color: #405faf;
$light-primary-color: lighten($primary-color, 40%);
$secondary-color: #e98300;
$light-secondary-color: lighten($secondary-color, 40%);
$tertiary-color: #051e5b;
$success-color: #00b2a9;
$warning-color: #fecb00;
$danger-color: #e2003c;
$dark-grey: #a0a0a0;
$grey: #d0d0d0;
$light-grey: #f0f0f0;
$white: #ffffff;
$black: #000000;

/* BORDER */
$border-radius: 4px;

/* BOX SHADOW */
$box-shadow: 0 0 27px rgba(0, 0, 0, 0.25);

/* FORMS */
$input-height: 37px;
$input-padding: 8px 16px;

/* BUTTONS */
$button-height: $input-height;
$button-padding: $input-padding;

/* CARDS */
$card-padding: 24px;

/* SECTIONS */
$section-gutter: 24px;

/* BREAKPOINTS */
$max-xl-breakpoint: 1199.98px; // Extra large devices (large desktops, 1200px and up)
$max-lg-breakpoint: 991.98px;  // Large devices (desktops, 992px and up)
$max-md-breakpoint: 767.98px;  // Medium devices (tablets, 768px and up)
$max-sm-breakpoint: 575.98px;  // Small devices (landscape phones, 576px and up)

$xl-breakpoint: 1200px; // Extra large devices (large desktops, 1200px and up)
$lg-breakpoint: 992px;  // Large devices (desktops, 992px and up)
$md-breakpoint: 768px;  // Medium devices (tablets, 768px and up)
$sm-breakpoint: 576px;  // Small devices (landscape phones, 576px and up)

/* BODY */
$body-bg: #efefef;
$font-color: $black;
$font-family: "Lato", Roboto, sans-serif;
$font-family-title: "RobotoSlab", Roboto, sans-serif;
$font-size: 16px;
$line-height: 19px;
$font-size-small: 14px;
$line-height-small: 17px;
$letter-spacing: normal;
$body-padding: 48px 24px;
$body-padding-sm: 36px 12px;
$container-max-width-sm: 720px;
$container-max-width-md: 960px;
$container-max-width-lg: 2000px;

/* SCROLLBAR */
$scrollbar-color: #a9a9a9;
$scrollbar-width: 10px;

/* HEADER */
$header-breakpoint: $lg-breakpoint;
$max-header-breakpoint: $max-lg-breakpoint;
$header-bg: $white;
$header-color: $black;
$header-height: 80px;
$header-height-mobile: 46px;
$header-logo-size: 50px;
$header-logo-size-mobile: 28px;
$box-shadow-header: 0 3px 13px rgba(0, 0, 0, 0.13);

/* FOOTER */
$footer-bg: $white;
$footer-color: $black;

/* SIDEMENU */
$sidemenu-breakpoint: $header-breakpoint;
$max-sidemenu-breakpoint: $max-header-breakpoint;
$sidemenu-bg: $primary-color;
$sidemenu-width: 250px;
$sidemenu-link-selected-bg: $secondary-color;
$sidemenu-text-color: $white;
$sidemenu-text-selected-color: $white;
