.otpKey-container {
  display: flex;

  input {
    height: 70px;
    border-radius: 10px;

    text-align: center;
    font-size: 35px;
    font-weight: bold;
    color: $primary-color;
  }
}