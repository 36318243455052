.app-divider {
  border-radius: 20px;
  @include divider-variant($black);

  &.divider-primary{
    @include divider-variant($primary-color);
  }
  &.divider-secondary{
    @include divider-variant($secondary-color);
  }
  &.divider-tertiary{
    @include divider-variant($tertiary-color);
  }
  &.divider-success{
    @include divider-variant($success-color);
  }
  &.divider-warning{
    @include divider-variant($warning-color);
  }
  &.divider-danger{
    @include divider-variant($danger-color);
  }
  &.divider-white{
    @include divider-variant($white);
  }
  &.divider-grey{
    @include divider-variant($grey);
  }
  &.divider-dark-grey{
    @include divider-variant($dark-grey);
  }
  &.divider-light-grey{
    @include divider-variant($light-grey);
  }
}
