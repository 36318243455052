.overflow-ellipsis{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cursor-pointer{
  cursor: pointer !important;
}

.font-weight-bold{
  font-weight: bold !important;
}

.font-weight-normal{
  font-weight: normal !important;
}

.no-border{
  border: none !important;
}

.list-style-none{
  list-style: none !important;
}
