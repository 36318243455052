.app-error-screen{
  .error-text-container{
    padding: 20px;
    width: 100%;
    max-width: 400px;

    @media (max-width: $max-sm-breakpoint) {
      padding: 0;
    }

    .error-text-404{
      font-family: "RobotoSlab", Roboto, sans-serif;
      color: $secondary-color;
      font-weight: bold;
      font-size: 150px;
      line-height: 180px;

      @media (max-width: $max-sm-breakpoint) {
        font-size: 120px;
        line-height: 140px;
      }
    }

    h3 {
      text-align: center;
    }
  }
}
