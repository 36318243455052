.ant-table{
  overflow-x: auto !important;

  .ant-table-container{
    .ant-table-content{
      table{
        border-bottom: 2px solid transparent;

        .ant-table-thead{
          tr{
            th.ant-table-cell{
              background: $white;
              font-size: $font-size-small;
              line-height: $line-height-small;
              font-weight: bold;
              border: none;

              &:before{
                content: none;
              }

              .ant-table-column-sorters{
                justify-content: flex-start;
                flex-direction: row-reverse;

                .ant-table-column-sorter{
                  color: $dark-grey;
                  margin-right: 8px;
                }
              }
            }
          }
        }
        .ant-table-tbody{
          tr.ant-table-row{

            &:nth-child(odd){
              td.ant-table-cell {
                background-color: $light-grey;
              }
            }

            td.ant-table-cell{
              font-size: $font-size-small;
              line-height: $line-height-small;
              background-color: $white;
              border: none;
            }
          }
          tr.ant-table-placeholder{

            td.ant-table-cell{
              background-color: $light-grey;
              font-size: $font-size-small;
              line-height: $line-height-small;
              border: none;
            }
          }
        }
      }
    }
  }
}

.ant-pagination.ant-table-pagination{
  margin: 16px 0 0 !important;
}
