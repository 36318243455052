/* BOOTSTRAP */
@import "~bootstrap/scss/bootstrap.scss";

/* CORE */
@import "./core/variables";
@import "./core/backgrounds";
@import "./core/texts";
@import "./core/fonts";
@import "./core/utilities";
@import "./core/spacings";

/* MIXINS */
@import "./mixins/mixins";

/* LAYOUT */
@import "./layout/layout";
@import "./layout/header";
@import "./layout/sidemenu";
@import "./layout/scrollbar";
@import "./layout/typos";

/* COMPONENTS */
@import "./components/buttons";
@import "./components/cards";
@import "./components/tables";
@import "./components/lists";
@import "./components/forms";
@import "./components/breadcrumbs";
@import "./components/ribbons";
@import "./components/icons";
@import "./components/badges";
@import "./components/tags";
@import "./components/dividers";
@import "./components/alerts";
@import "./components/search";
@import "./components/otp";
@import "./components/modals";
@import "./components/banners";
@import "./components/toasts";
@import "./components/items";
@import "./components/links";
@import "./components/sections";
@import "./components/tooltips";

/* VIEWS */
// Ajouter ici les styles spécifiques à une vue (en dernier recours)
@import "./views/error";
@import "./views/maintenance";
