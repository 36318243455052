.search {
  .ant-input-group-addon{
    button.ant-btn{
      height: $input-height !important;
      min-width: $input-height;
      max-width: $input-height;
      padding: 0;

      @include btn-variant($primary-color, $primary-color, $white);

      svg{
        height: 22px;
        width: 22px;
      }
    }
  }
}
