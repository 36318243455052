.app-card{
  background-color: $white;
  border-radius: $border-radius;
  border: 1px solid $white;
  padding: $card-padding;

  &.card-hover:hover{
    border-color: darken($white, 15%);
    cursor: pointer;
  }

  &.card-dashboard{
    width: 300px;
    border: 2px solid $primary-color;
    background-color: $primary-color;
    color: $white;
    align-self: stretch;

    &:hover{
      border: 2px solid $primary-color;
      background-color: $white;
      color: $primary-color;
    }

    .anticon{
      font-size: 48px;
    }
    .dashboard-card-text{
      font-size: 21px;
      text-align: center;
    }
  }
}
