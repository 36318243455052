.text-primary{
  color: $primary-color !important;
}
.text-secondary{
  color: $secondary-color !important;
}
.text-tertiary{
  color: $tertiary-color !important;
}
.text-success{
  color: $success-color !important;
}
.text-warning{
  color: $warning-color !important;
}
.text-danger{
  color: $danger-color !important;
}
.text-black{
  color: $black !important;
}
.text-white{
  color: $white !important;
}
.text-grey{
  color: $grey !important;
}
.text-dark-grey{
  color: $dark-grey !important;
}
.text-light-grey{
  color: $light-grey !important;
}
