.app-alert{
  border-radius: $border-radius;
  border: 1px solid $black;
  background-color: transparent;
  padding: $card-padding;

  .icon{
    svg{
      height: 30px;
      width: 30px;
    }
  }

  &.alert-primary{
    @include alert-variant($primary-color);
  }
  &.alert-primary-lighten{
    @include alert-variant-lighten($primary-color, $light-primary-color);
  }
  &.alert-secondary{
    @include alert-variant($secondary-color);
  }
  &.alert-secondary-lighten{
    @include alert-variant-lighten($secondary-color, $light-secondary-color);
  }
  &.alert-tertiary{
    @include alert-variant($tertiary-color);
  }
  &.alert-success{
    @include alert-variant($success-color);
  }
  &.alert-warning{
    @include alert-variant($warning-color);
  }
  &.alert-danger{
    @include alert-variant($danger-color);
  }
}
