a{
  color: $secondary-color;
  text-decoration: none;

  &:focus,
  &:hover{
    color: $secondary-color;
    text-decoration: underline;
  }
}
