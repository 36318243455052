$spaceamounts: (10, 20, 30, 40, 50);
$sides: (top, bottom, left, right);
$breakpoints: (sm: $sm-breakpoint, md: $md-breakpoint, lg: $lg-breakpoint, xl: $xl-breakpoint);

@each $space in $spaceamounts {
  @each $side in $sides {
    .m#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}px !important;
    }

    .p#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space}px !important;
    }

    @each $key, $val in $breakpoints {
      @media (min-width: #{$val}) {
        .m#{str-slice($side, 0, 1)}-#{$key}-#{$space} {
          margin-#{$side}: #{$space}px !important;
        }

        .p#{str-slice($side, 0, 1)}-#{$key}-#{$space} {
          padding-#{$side}: #{$space}px !important;
        }
      }
    }
  }
  .my-#{$space} {
    margin-top: #{$space}px !important;
    margin-bottom: #{$space}px !important;
  }
  .mx-#{$space} {
    margin-left: #{$space}px !important;
    margin-right: #{$space}px !important;
  }
  .m-#{$space} {
    margin: #{$space}px !important;
  }

  .py-#{$space} {
    padding-top: #{$space}px !important;
    padding-bottom: #{$space}px !important;
  }
  .px-#{$space} {
    padding-left: #{$space}px !important;
    padding-right: #{$space}px !important;
  }
  .p-#{$space} {
    padding: #{$space}px !important;
  }

  @each $key, $val in $breakpoints {
    @media (min-width: #{$val}) {
      .my-#{$key}-#{$space} {
        margin-top: #{$space}px !important;
        margin-bottom: #{$space}px !important;
      }
      .mx-#{$key}-#{$space} {
        margin-left: #{$space}px !important;
        margin-right: #{$space}px !important;
      }
      .m-#{$key}-#{$space} {
        margin: #{$space}px !important;
      }

      .py-#{$key}-#{$space} {
        padding-top: #{$space}px !important;
        padding-bottom: #{$space}px !important;
      }
      .px-#{$key}-#{$space} {
        padding-left: #{$space}px !important;
        padding-right: #{$space}px !important;
      }
      .p-#{$key}-#{$space} {
        padding: #{$space}px !important;
      }
    }
  }
}
