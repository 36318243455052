.app-sidemenu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
  background-color: rgba(0, 0, 0, 0.3);

  &.sidemenu-with-header{
    top: $header-height;

    @media (max-width: $max-header-breakpoint) {
      top: $header-height-mobile;
    }
  }

  @media (min-width: $sidemenu-breakpoint) {
    display: none !important;
  }
}

.btn-side-menu{
  position: absolute;
  top: 0;
  right: 0;

  &:not(.sidemenu-closable-on-desktop){
    @media (min-width: $sidemenu-breakpoint){
      display: none !important;
    }
  }
}

.btn-side-menu,
.btn-side-menu:focus,
.btn-side-menu:active {
  box-shadow: none !important;
  border-radius: 0 !important;
  min-height: $header-height !important;
  max-width: $header-height !important;
  min-width: $header-height !important;
  padding: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent !important;
  border-color: transparent !important;
  color: $header-color !important;

  @media (max-width: $max-header-breakpoint) {
    min-height: $header-height-mobile !important;
    max-width: $header-height-mobile !important;
    min-width: $header-height-mobile !important;
  }

  svg{
    fill: $header-color !important;

    path{
      fill: $header-color !important;
    }
  }

  &:hover{
    background-color: $primary-color !important;
    border-color: $primary-color !important;
    color: $white !important;

    svg{
      fill: $white !important;

      path{
        fill: $white !important;
      }
    }
  }
}

.app-sidemenu {
  background-color: $sidemenu-bg;
  max-width: $sidemenu-width;
  min-width: $sidemenu-width;

  &.mobile-sidemenu-open{
    left: 0;
    &.sidemenu-closable-on-desktop{
      @media (min-width: $sidemenu-breakpoint) {
        margin-left: 0;
      }
    }
  }

  @media (max-width: $max-sidemenu-breakpoint) {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -$sidemenu-width;
    transition: left 0.5s;
    z-index: 10;
  }
  &.sidemenu-closable-on-desktop{
    @media (min-width: $sidemenu-breakpoint) {
      margin-left: -$sidemenu-width;
      transition: margin-left 0.5s;
      z-index: 10;
    }
  }

  .btn-side-menu{
    position: absolute;
    top: 0;
    right: -36px;
  }
  &:not(.sidemenu-closable-on-desktop) {
    .btn-side-menu{
      @media (min-width: $sidemenu-breakpoint) {
        display: none !important;
      }
    }
  }

  .sidemenu-container{
    max-height: 100vh;
    overflow-y: auto;

    &::-webkit-scrollbar {
      display: none !important;
    }

    .link{
      padding: 15px 25px;
      text-decoration: none;
      color: $sidemenu-text-color;
      font-weight: bold;

      &:hover{
        text-decoration: none;
      }

      &:hover,
      &.selected,
      &.link-active{
        background-color: $sidemenu-link-selected-bg;
        color: $sidemenu-text-selected-color;
      }
    }

    .ant-collapse.sidemenu-link-collapse{
      border: none;
      background-color: $sidemenu-bg;
      .ant-collapse-item{
        border: none;
        .ant-collapse-header{
          padding: 15px 44px 15px 25px;
          font-weight: bold;
          color: $sidemenu-text-color;
          border-radius: 0;
          line-height: $line-height;

          .ant-collapse-arrow{
            right: 20px;
          }

          &:hover,
          &.selected{
            background-color: $sidemenu-link-selected-bg;
            color: $sidemenu-text-selected-color;
          }
        }
        .ant-collapse-content{
          border: none;
          background-color: lighten($sidemenu-bg, 35%);
          border-radius: 0;

          .ant-collapse-content-box{
            padding: 15px 25px 15px 35px;
            .collapse-link{
              &:not(:last-child){
                margin-bottom: 20px;
              }

              a{
                font-weight: bold;
                color: $sidemenu-bg;
                text-decoration: none;

                &:hover{
                  color: $sidemenu-link-selected-bg;
                }
              }

              &.collapse-link-active{
                a{
                  text-decoration: underline;
                }
              }
            }
          }
        }
        &.ant-collapse-item-active{
          .ant-collapse-header{
            background-color: lighten($sidemenu-bg, 35%);
            color: $sidemenu-bg;
          }
        }
      }

      &.sidemenu-link-collapse-active{
        .ant-collapse-item{
          .ant-collapse-header{
            background-color: $sidemenu-link-selected-bg;
            color: $sidemenu-text-selected-color;
          }
        }
      }
    }

    .user-container{
      padding: 40px 25px;

      @media (min-width: $header-breakpoint) {
        display: none !important;
      }
    }
  }

  &.sidemenu-with-header{
    &:not(.sidemenu-closable-on-desktop){
      @media (max-width: $max-header-breakpoint) {
        top: $header-height-mobile;
      }
    }
    &.sidemenu-closable-on-desktop{
      top: $header-height;

      @media (max-width: $max-header-breakpoint) {
        top: $header-height-mobile;
      }
    }

    .sidemenu-container{
      max-height: calc(100vh - #{$header-height});

      @media (max-width: $max-header-breakpoint) {
        max-height: calc(100vh - #{$header-height-mobile});
      }
    }
  }
}
