.ant-tooltip{
  .ant-tooltip-arrow{
    .ant-tooltip-arrow-content{
      background-color: $black;
    }
  }
  .ant-tooltip-inner{
    background-color: $black;
    font-size: $font-size;
    line-height: $line-height;
    border-radius: $border-radius;
    padding: 8px 16px;
    color: $white;
  }
}
