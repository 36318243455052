.Toastify{
  .Toastify__toast-container{
    .Toastify__toast{
      position: relative;
      padding: 8px 16px;
      font-size: $font-size-small;
      line-height: $line-height-small;
      border-radius: $border-radius;
      color: white;

      .Toastify__close-button{
        position: absolute;
        top: 3px;
        right: 5px;
        outline: none !important;
      }

      &.Toastify__toast--info {
        @include toast-variant($primary-color);
      }
      &.Toastify__toast--success {
        @include toast-variant($success-color);
      }
      &.Toastify__toast--error {
        @include toast-variant($danger-color);
      }
    }
  }
}
